import axios from 'axios';
import {ApiFailResponse} from "./interfaces/apiTypes";


export const handleAxiosError = (error: unknown): ApiFailResponse => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            return {message: error.response.data.message || 'A aparut o eroare necunoscuta'};
        }
        if (error.request) {
            return {message: 'Eroare necunoscuta'};
        }

        return {message: error.message};

    } else {
        return {message: 'Eroare necunoscuta'};
    }
};
