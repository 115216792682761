import React, {useEffect, useState} from "react";
import useBannerStyles from "../styles/home/banner_home";
import {isAfterOctober14, isAfterSeptember16th, isBeforeNovember15th, isBeforeOctober27} from "../utils/dateTimesUtils";
import BannerVoiceRo from "./vocea_romaniei/BannerVoceaRomaniei";
import BannerHomeNcpOctober from './ncp/promoProfiOctober/BannerHome';
import {Grid} from "@mui/material";
import Slider from "./Slider";

const BannerHome: React.FC = () => {
    const styles = useBannerStyles();
    const banners = [];
    const [interval, setInterval] = useState(450);

    useEffect(() => {
        if (interval === 450) {
            setTimeout(() => {
                setInterval(3000)
            }, 500)
        }
    }, []);

    if (isAfterSeptember16th() && isBeforeNovember15th()) {
        banners.push(<BannerVoiceRo key="banner-voice-ro"/>);
    }

    if (isAfterOctober14() && isBeforeOctober27()) {
        banners.push(<BannerHomeNcpOctober key="banner-home-ncp-october"/>);
    }

    if (banners.length === 0) {
        return <></>;
    }

    if (banners.length === 1) {
        return <>
            {banners}
        </>;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Slider interval={5000}>
                    {banners}
                </Slider>
            </Grid>
        </Grid>
    );
}

export default BannerHome;