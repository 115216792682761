import React, {useState} from "react";
import {Box, Button, Grid, Link, Typography} from "@mui/material";
import logo_kozel_meeet_challenge from "../../assets/images/untold/logo_kozel_meeet_challenge.png";
import poza_influenceri from '../../assets/images/kozel_meet/poza_influenceri.png'
import useMeetInfluencers from "../../styles/kozelMeet/meetInfluencers";
import video_influencers from '../../assets/videos/video_influencers.webm';
import clsx from "clsx";
import imagesCarousels from './importImages';
import SlideShow from "../SlideShow";
import VideoPlayer from "../VideoPlayer";
import {useAppSelector} from "../../stores/store";
import logo_untold_kozel_meeet from '../../assets/images/untold/logo_untold_kozel_meeet.png';
import message_icon1 from '../../assets/images/untold/message_icon1.png';
import message_icon2 from '../../assets/images/untold/message_icon2.png';
import message_icon3 from '../../assets/images/untold/message_icon3.png';
import message_mobile_icon1 from '../../assets/images/untold/message_icon1_mobile.png';
import message_mobile_icon2 from '../../assets/images/untold/message_icon2_mobile.png';
import message_mobile_icon3 from '../../assets/images/untold/message_icon3_mobile.png';
import map_challenge_untold from '../../assets/images/untold/map_challenge_untold.png';
import clock_icon from '../../assets/images/untold/clock_icon.png';
import theme from "../../styles/themes/default";
import TeaserFrame2 from "../TeaserFrame2";
import useIsScreenDownLg from "../../hooks/useIsScreenDownLg";
import PopupComponent from "../kozel/PopupComponent";


const MeetInfluencers: React.FC = () => {
    const styles = useMeetInfluencers();
    const isScreenDownLg = useIsScreenDownLg();
    const {isAuthenticated} = useAppSelector((state) => state.auth);
    const [isOpenPopupMap, setIsOpenPopupMap] = useState(false);

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12} className={styles.containerItem}>
                <img src={logo_untold_kozel_meeet} alt={'logo_untold_kozel_meeet'}
                     className={styles.imgBanner}/>
            </Grid>
            <Grid item xs={12} className={styles.containerItem}>
                <Box
                    sx={{
                        width: '10vw',
                        height: '1px',
                        borderRadius: 1,
                        bgcolor: theme.palette.primary.light,
                    }}
                />
                <Typography className={styles.introducingText}>INTRODUCING</Typography>
                <Box
                    sx={{
                        width: '10vw',
                        height: '1px',
                        borderRadius: 1,
                        bgcolor: theme.palette.primary.light,
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={10} className={styles.containerItem}>
                <img
                    src={logo_kozel_meeet_challenge}
                    alt={'logo_kozel_meeet_challenge'}
                    className={styles.imgBanner}
                    style={{
                        maxWidth: '800px'
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={10} className={styles.containerItem}>
                <Typography className={styles.titleMeetInf}>Așteptarea a luat sfârșit!</Typography>
            </Grid>
            <Grid item xs={12} lg={10} className={styles.containerItem}>
                <Typography className={styles.subtitleMeetInf}>Să înceapă provocarea!</Typography>
            </Grid>
            <Grid item xs={12} className={clsx(styles.wrapperBoxInfluencers)}>
                <Grid container className={styles.wrapperBoxInfluencersContainer}>
                    <Grid item xs={12} lg={10} className={clsx(styles.boxInfluencers)}>
                        <Grid container>
                            <Grid item xs={12} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                                <Typography className={clsx(styles.boxInfluencersTitle, styles.spaceBottom)}><Link
                                    href={'https://www.instagram.com/lauramusuroaea/'}
                                    target="_blank">@LauraMușuroaea</Link>
                                    , <Link href={'https://www.instagram.com/mariciu/'}
                                            target="_blank">@Mariciu</Link>&nbsp;
                                    si&nbsp;<Link href={'https://www.instagram.com/vladgrigorescu/'}
                                                  target="_blank">@VladGrigorescu</Link>&nbsp;
                                    sunt deja aici cu echipele lor și au dat <span
                                        className={'colorSand'}>START</span>&nbsp;provocărilor&nbsp;
                                    <span className={'colorSand'}>Kozel Meeet</span> @UNTOLD!</Typography>
                                <Typography className={styles.boxInfluencersTitle}>Participă cu grupul tău de
                                    prieteni sau cu noii prieteni pe care ți-i faci aici, rezolvați toate provocările și
                                    bucurați-vă de gustul berii ȚAPene din Cehia!</Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.wrapperBoxInfluencersImg}>
                <img src={poza_influenceri} alt={'poza_influenceri'} className={styles.boxInfluencersImg}/>
            </Grid>
            <Grid item xs={12} className={styles.wrapperTeaser}>
                <TeaserFrame2
                    title={<Typography className={styles.teaserTitle}>Ce ai de făcut?</Typography>}
                    titleMarginTop={'min(-5vh,-5vw)'}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                >
                    <Grid container>
                        <Grid item xs={12} className={styles.teaserWrapperItem}>
                            <Grid container className={styles.teaserContainer}>
                                <Grid item xs={12} lg={4} className={clsx(styles.teaserItem, styles.boxImg)}>
                                    {isScreenDownLg ?
                                        <img src={message_mobile_icon1} alt={'message_mobile_icon1'}
                                             className={styles.img}/> :
                                        <img src={message_icon1} alt={'message_icon1'} className={styles.img}/>}
                                </Grid>
                                <Grid item xs={12} lg={8} className={clsx(styles.teaserItem, styles.boxText)}>
                                    <Typography className={styles.teaserText}>Te asiguri că ai gașca formată! Fie că ați
                                        venit împreună sau v-ați găsit aici, e sigur că vă veți distra pe
                                        cinste!</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserWrapperItem}>
                            <Grid container className={styles.teaserContainer}>
                                <Grid item xs={12} lg={8} className={clsx(styles.teaserItem, styles.boxText)}>
                                    <Typography
                                        className={clsx(styles.teaserText, isScreenDownLg ? '' : styles.textRight)}>Mergi
                                        la insula
                                        Kozel, scanează codurile QR și rezolvă provocările cu echipa ta!</Typography>
                                </Grid>
                                <Grid item xs={12} lg={4} className={clsx(styles.teaserItem, styles.boxImg)}>
                                    {isScreenDownLg ?
                                        <img src={message_mobile_icon2} alt={'message_mobile_icon2'}
                                             className={styles.img}/> :
                                        <img src={message_icon2} alt={'message_icon2'} className={styles.img}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={styles.teaserWrapperItem}>
                            <Grid container className={styles.teaserContainer}>
                                <Grid item xs={12} lg={4} className={clsx(styles.teaserItem, styles.boxImg)}>
                                    {isScreenDownLg ?
                                        <img src={message_mobile_icon3} alt={'message_mobile_icon2'}
                                             className={styles.img}/> :
                                        <img src={message_icon3} alt={'message_icon3'} className={styles.img}/>}
                                </Grid>
                                <Grid item xs={12} lg={8} className={clsx(styles.teaserItem, styles.boxText)}>
                                    <Typography className={styles.teaserText}>Mergi la zona KOZEL MEET de lângă barul
                                        gigant. Celebrează cu prietenii pentru că la Kozel este întotdeauna loc pentru
                                        încă un prieten!</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
            {isAuthenticated && <Grid item xs={12} className={clsx(styles.containerItem, styles.mapBox)}>
                <Typography className={styles.textGoMap}>Accesează harta și vino la Kozel Țap Land să dați START
                    distracției și premiilor</Typography>
                <Button className={styles.button} onClick={() => {
                    setIsOpenPopupMap(true)
                }}>VEZI HARTA AICI</Button>
                <img src={clock_icon} alt={'clock_icon'} className={styles.img} style={{maxWidth: '200px'}}/>
            </Grid>}
            {isAuthenticated && <Grid item xs={12} className={clsx(styles.containerItem, styles.mapBox)}>
                <Typography className={styles.titleRitual}>RITUALUL BERII INALTATOARE</Typography>
                <Grid container className={styles.timeIntervalWrapperContainer}>
                    <Grid item xs={12} className={styles.timeIntervalWrapperItem}>
                        <Grid container className={styles.timeIntervalContainer}>
                            <Grid item className={styles.boxTime}>21:00</Grid>
                            <Grid item className={styles.boxClockIcon}>
                                <img src={clock_icon} alt={'clock_icon'} className={styles.img}/>
                            </Grid>
                            <Grid item className={styles.boxTime}>22:00</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            {isAuthenticated && <Grid item xs={12} className={styles.containerItem}>
                <Typography className={styles.keepEyesOnUsTest}>Fii pe fază, în fiecare seară de la orele 21:00 și 22:00
                    ai o bere Kozel MIX din partea
                    noastră! Asigură-te ca ești prezent la una din cele 2 zone Kozel de lângă Roată! Barul gigant Kozel
                    și
                    Kozel Meeet!</Typography>
            </Grid>}
            {isAuthenticated && <Grid item xs={12} className={styles.containerItem}>
                <Typography className={styles.prepareSurpriseText}>Îți pregătim o surpriză ȚAPănă!</Typography>
            </Grid>}
            <Grid item xs={12} lg={10} className={styles.wrapperTextVideo}>
                <Grid container>
                    <Grid item xs={12} lg={6} className={styles.containerText}>
                        <Typography className={styles.titleQuestDay}>
                            Vezi cum s-au distrat și provocat echipele la QUEST DAY, în iunie!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} className={styles.containerVideo}>
                        <VideoPlayer src={video_influencers} type="video/webm"/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.containerSlideShow}>
                <SlideShow
                    duration={10}
                    isForwardDir={true}
                    images={imagesCarousels.filter((_, index) => index % 2 === 0)}
                    slidesToShow={5}
                />
            </Grid>
            <Grid item xs={12} className={styles.containerSlideShow}>
                <SlideShow
                    duration={10}
                    isForwardDir={false}
                    images={imagesCarousels.filter((_, index) => index % 2 !== 0)}
                    slidesToShow={5}
                />
            </Grid>
            {isAuthenticated && (
                <PopupComponent isOpen={isOpenPopupMap} onClose={() => {
                    setIsOpenPopupMap(false)
                }}>
                    <img src={map_challenge_untold} alt={'map_challenge_untold'} className={styles.img}/>
                </PopupComponent>
            )}
        </Grid>
    )
}
export default MeetInfluencers;
