import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import useTeaserTeamAndGame from "../../styles/kozelMeet/teaserTeamAndGame";
import clsx from "clsx";
import createGroup from '../../assets/images/kozel_meet/createGroup.gif';
import spin_bottle_dark from '../../assets/images/kozel_meet/spin_bottle_dark.png';
import spin_bottle_premium from '../../assets/images/kozel_meet/spin_bottle_premium.png';
import ImageComponent from "../../utils/ImageComponent";
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {setOption} from "../../features/games/gameSlice";
import {paths} from "../../AppRouters";
import {gameNameApi} from "./BottleGame";


const TeaserTeamAndGame: React.FC = () => {
    const teaserTeamAndGame = useTeaserTeamAndGame();
    const dispatch = useAppDispatch();
    const {games} = useAppSelector((state) => state.game);
    const {option} = games[gameNameApi];
    const handleChangeBottle = () => {
        dispatch(setOption({
            bottle: (option && option.bottle === 'dark') ? 'premium' : 'dark',
            gameName: gameNameApi,
        }))
    }
    return (
        <Grid container>
            <Grid item xs={12} className={teaserTeamAndGame.wrapperContainer}>
                <Grid container className={teaserTeamAndGame.container}>
                    <Grid item xs={12} className={teaserTeamAndGame.containerItem}>
                        <Grid container className={teaserTeamAndGame.sectionContainer}>
                            <Grid item lg={4} xs={12}
                                  className={clsx(teaserTeamAndGame.sectionContainerItem, teaserTeamAndGame.sectionContainerItemPaddingRight)}>
                                <ImageComponent src={createGroup} alt={'createGroup'}/>
                            </Grid>
                            <Grid item lg={8} xs={12} className={teaserTeamAndGame.sectionContainerItem}>
                                <Typography className={teaserTeamAndGame.sectionText}>Invită-ți prietenii în comunitatea
                                    Kozel și împărtășiți experiențe, provocări, premii și berea ȚAPănă din
                                    Cehia!</Typography>
                                <NavLink to={paths.myGroup}
                                         className={teaserTeamAndGame.sectionButton}>{'ÎMI CREEZ ECHIPA'}</NavLink>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}
                          className={clsx(teaserTeamAndGame.containerItem, teaserTeamAndGame.containerItemSeparator)}>
                        <Grid container className={teaserTeamAndGame.sectionContainer}>
                            <Grid item lg={8} xs={12}
                                  className={clsx(teaserTeamAndGame.sectionContainerItem, teaserTeamAndGame.sectionContainerItemPaddingRight)}>
                                <Typography className={teaserTeamAndGame.sectionText}>Accesează prima provocare Kozel
                                    Meeet, distrează-te împreună cu echipa ta și câștigați puncte împreună.</Typography>
                                <Typography className={teaserTeamAndGame.sectionTextBig}>Învârte sticla și acceptă
                                    provocarea!</Typography>
                                <NavLink to={paths.gameRollBottle} className={teaserTeamAndGame.sectionButton}>START
                                    PROVOCARE</NavLink>
                            </Grid>
                            <Grid item lg={4} xs={12} className={teaserTeamAndGame.sectionContainerItem}>
                                <Button onClick={handleChangeBottle}
                                        className={teaserTeamAndGame.buttonChangeBottle}>Schimbă sticla</Button>
                                <ImageComponent src={(option && option.bottle === 'dark') ? spin_bottle_dark : spin_bottle_premium}
                                                alt={(option && option.bottle === 'dark') ? 'spin_bottle_dark' : 'spin_bottle_premium'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TeaserTeamAndGame;