import React, {useRef} from 'react';
import useVideoPlayerStyles from "../styles/videoPlayerStyles";

interface VideoPlayerProps {
    src: string;
    type: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({src, type}) => {
    const videoPlayerStyles = useVideoPlayerStyles();
    const videoRef = useRef<HTMLVideoElement>(null);
    // const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div className={videoPlayerStyles.container}>
            <video
                ref={videoRef}
                controls
                className={videoPlayerStyles.video}
                // onPlay={() => setIsPlaying(true)}
                // onPause={() => setIsPlaying(false)}
            >
                <source src={src} type={type}/>
            </video>
        </div>
    );
};

export default VideoPlayer;
