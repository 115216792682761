import React, {useCallback, useState} from 'react';
import {
    GoogleMap,
    InfoWindowF,
    MarkerClustererF,
    MarkerF,
    useJsApiLoader
} from '@react-google-maps/api';
import {listLocation} from '../components/locations/listLocations';

const containerStyle = {
    width: '75vw',
    height: '75vh',
};

const center = {
    lat: 46,
    lng: 25.5,
};

interface Location {
    name: string;
    lat: number;
    lng: number;
    zIndex: number;
}

const locations: Location[] = listLocation;

const KozelLocations: React.FC = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API}`
    })
    const [selected, setSelected] = useState<Location|null>(null);

    const onSelect = useCallback((item:Location) => {
        setSelected(item);
    }, []);
    const getGoogleMapsUrl = (lat: number, lng: number) => {
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={7.2}
        >
            <MarkerClustererF>
                {(clusterer) =>(
                    <>
                        {locations.map((location, index) => (
                            <MarkerF
                                key={index}
                                position={location}
                                clusterer={clusterer}
                                onClick={() => onSelect(location)}
                            />
                        ))}
                    </>
                )
                }
            </MarkerClustererF>
            {selected && (
                <InfoWindowF
                    position={selected}
                    onCloseClick={() => setSelected(null)}
                >
                    <div>
                        <h2>{selected.name}</h2>
                        <a href={getGoogleMapsUrl(selected.lat, selected.lng)} target={'_blank'}>Navigate to Destination</a>
                    </div>
                </InfoWindowF>
            )}
        </GoogleMap>
    ) : <></>;
};

export default KozelLocations;
