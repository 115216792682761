// importImages.ts
import dcsf0014 from '../../assets/images/kozel_meet/carousel/DSCF0014.jpg';
import dcsf0021 from '../../assets/images/kozel_meet/carousel/DSCF0021.jpg';
import dcsf0031 from '../../assets/images/kozel_meet/carousel/DSCF0031.jpg';
import dcsf0054 from '../../assets/images/kozel_meet/carousel/DSCF0054.jpg';
import dcsf0056 from '../../assets/images/kozel_meet/carousel/DSCF0056.jpg';
import dcsf0066 from '../../assets/images/kozel_meet/carousel/DSCF0066.jpg';
import dcsf0077 from '../../assets/images/kozel_meet/carousel/DSCF0077.jpg';
import dcsf0081 from '../../assets/images/kozel_meet/carousel/DSCF0081.jpg';
import dcsf0086 from '../../assets/images/kozel_meet/carousel/DSCF0086.jpg';
import dcsf0095 from '../../assets/images/kozel_meet/carousel/DSCF0095.jpg';
import dcsf0096 from '../../assets/images/kozel_meet/carousel/DSCF0096.jpg';
import dcsf0109 from '../../assets/images/kozel_meet/carousel/DSCF0109.jpg';
import dcsf0120 from '../../assets/images/kozel_meet/carousel/DSCF0120.jpg';
import dcsf0124 from '../../assets/images/kozel_meet/carousel/DSCF0124.jpg';
import dcsf0132 from '../../assets/images/kozel_meet/carousel/DSCF0132.jpg';
import dcsf0161 from '../../assets/images/kozel_meet/carousel/DSCF0161.jpg';
import dcsf0169 from '../../assets/images/kozel_meet/carousel/DSCF0169.jpg';
import dcsf0201 from '../../assets/images/kozel_meet/carousel/DSCF0201.jpg';
import dcsf0232 from '../../assets/images/kozel_meet/carousel/DSCF0232.jpg';
import dcsf0237 from '../../assets/images/kozel_meet/carousel/DSCF0237.jpg';
import dcsf0253 from '../../assets/images/kozel_meet/carousel/DSCF0253.jpg';
import dcsf0278 from '../../assets/images/kozel_meet/carousel/DSCF0278.jpg';
import dcsf0287 from '../../assets/images/kozel_meet/carousel/DSCF0287.jpg';
import dcsf0295 from '../../assets/images/kozel_meet/carousel/DSCF0295.jpg';
import dcsf0299 from '../../assets/images/kozel_meet/carousel/DSCF0299.jpg';
import dcsf0345 from '../../assets/images/kozel_meet/carousel/DSCF0345.jpg';
import dcsf0355 from '../../assets/images/kozel_meet/carousel/DSCF0355.jpg';
import dcsf0367 from '../../assets/images/kozel_meet/carousel/DSCF0367.jpg';
import dcsf0417 from '../../assets/images/kozel_meet/carousel/DSCF0417.jpg';
import dcsf0418 from '../../assets/images/kozel_meet/carousel/DSCF0418.jpg';
import dcsf4197 from '../../assets/images/kozel_meet/carousel/DSCF4197.jpeg';
import dcsf6025 from '../../assets/images/kozel_meet/carousel/DSCF6025.jpg';

const images = [
    {src: dcsf0014, alt: 'dcsf0014',},
    {src: dcsf0021, alt: 'dcsf0021',},
    {src: dcsf0031, alt: 'dcsf0031',},
    {src: dcsf0054, alt: 'dcsf0054',},
    {src: dcsf0056, alt: 'dcsf0056',},
    {src: dcsf0066, alt: 'dcsf0066',},
    {src: dcsf0077, alt: 'dcsf0077',},
    {src: dcsf0081, alt: 'dcsf0081',},
    {src: dcsf0086, alt: 'dcsf0086',},
    {src: dcsf0095, alt: 'dcsf0095',},
    {src: dcsf0096, alt: 'dcsf0096',},
    {src: dcsf0109, alt: 'dcsf0109',},
    {src: dcsf0120, alt: 'dcsf0120',},
    {src: dcsf0124, alt: 'dcsf0124',},
    {src: dcsf0132, alt: 'dcsf0132',},
    {src: dcsf0161, alt: 'dcsf0161',},
    {src: dcsf0169, alt: 'dcsf0169',},
    {src: dcsf0201, alt: 'dcsf0201',},
    {src: dcsf0232, alt: 'dcsf0232',},
    {src: dcsf0237, alt: 'dcsf0237',},
    {src: dcsf0253, alt: 'dcsf0253',},
    {src: dcsf0278, alt: 'dcsf0278',},
    {src: dcsf0287, alt: 'dcsf0287',},
    {src: dcsf0295, alt: 'dcsf0295',},
    {src: dcsf0299, alt: 'dcsf0299',},
    {src: dcsf0345, alt: 'dcsf0345',},
    {src: dcsf0355, alt: 'dcsf0355',},
    {src: dcsf0367, alt: 'dcsf0367',},
    {src: dcsf0417, alt: 'dcsf0417',},
    {src: dcsf0418, alt: 'dcsf0418',},
    {src: dcsf4197, alt: 'dcsf4197',},
    {src: dcsf6025, alt: 'dcsf6025',},
]
export default images;
