import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Grid
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../stores/store";
import {logout, openLoginModal} from "../features/auth/authSlice";
import logoPng from '../assets/images/logo.png';
import burger_icon from '../assets/images/burger_icon.png';
import useNavbarStyles from "../styles/navbar";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import clsx from "clsx";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {paths} from "../AppRouters";

const Navbar: React.FC = () => {
    const dispatch = useAppDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const navbarStyle = useNavbarStyles();

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: 'Home', path: paths.home },
        { text: 'Promoții', path: paths.promotii },
        { text: 'Kozel Meeet', path: paths.kozelMeet },
        { text: 'Despre Kozel', path: paths.aboutKozel },
        { text: 'Berile Noastre', path: paths.ourBeers },
        { text: 'Tradiția Noastră', path: paths.ourTradition },
        { text: 'Ritualurile de Servire', path: paths.servingRitual },
        { text: 'Regulamente', path: paths.rules },
    ];

    if (isAuthenticated) {
        const authenticatedMenuItems = [
            { text: 'Profil', path: paths.profile },
            { text: 'Grupul meu', path: paths.myGroup },
        ];

        menuItems.splice(1, 0, ...authenticatedMenuItems);
    }

    const handleLogout = () => {
        dispatch(logout());
    }

    const handleLogin = () => {
        dispatch(openLoginModal());
    }

    return (
        <>
            <AppBar position="sticky" className={navbarStyle.appBar}>
                <Toolbar className={navbarStyle.toolbar}>
                    <NavLink to={paths.home}><img src={logoPng} alt={'Kozel'} className={navbarStyle.logo}/></NavLink>
                    <IconButton edge="start" color="inherit" aria-label="menu"
                                onClick={toggleDrawer(true)}
                                className={navbarStyle.burgerButton}
                    >
                        <img src={burger_icon} alt={'Burger Icon'}
                            className={navbarStyle.burgerIcon}
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} className={navbarStyle.navbarDrawer}>
                <HighlightOffIcon className={navbarStyle.closeButtonHeader} onClick={toggleDrawer(false)}/>
                <Grid container className={navbarStyle.containerHeader}>
                    <Grid item className={navbarStyle.containerHeaderItem}>
                        <img src={logoPng} alt={'logoPng'}  className={navbarStyle.logoHeader}/>
                    </Grid>
                    <Grid item className={clsx(navbarStyle.containerHeaderItem,navbarStyle.ctas)}>
                        {isAuthenticated && <Button className={navbarStyle.button} onClick={e => {setDrawerOpen(false); handleLogout();}}><LogoutIcon />Logout</Button>}
                        {!isAuthenticated &&
                                <Button className={navbarStyle.button} onClick={e => {setDrawerOpen(false); handleLogin(); }}><LoginIcon />Login</Button>}
                        {!isAuthenticated &&
                                <NavLink to={paths.register} onClick={e => {setDrawerOpen(false)}}><Button className={navbarStyle.button}><HowToRegIcon />Cont nou</Button></NavLink>}
                    </Grid>
                </Grid>
                <List className={navbarStyle.navbarDrawerList}>
                    {menuItems.map((item) => (
                        <ListItem  className={navbarStyle.navbarDrawerListItem} component={NavLink} to={item.path} key={item.text} onClick={toggleDrawer(false)}>
                            <ListItemText primary={item.text}  className={navbarStyle.navbarDrawerListItemText}/>
                        </ListItem>
                    ))}
                   </List>
            </Drawer>
        </>
    );
};

export default Navbar;
