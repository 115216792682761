export const listLocation = [
    {name: "CCD VALHALLA", lat: 45.75544, lng: 22.9047, zIndex: 100},
    {name: "LINZ", lat: 44.63297, lng: 22.6614, zIndex: 100},
    {name: "RIVIERA MG PARTIZAN", lat: 44.677235, lng: 22.329312, zIndex: 100},
    {name: "BAR PAVILION", lat: 45.247639, lng: 24.3382, zIndex: 100},
    {name: "DANOS IMPEX", lat: 45.10603, lng: 24.36186, zIndex: 100},
    {name: "FLORIASOL", lat: 45.10267, lng: 24.3748, zIndex: 100},
    {name: "LA TRATTORIA", lat: 45.08444, lng: 24.36792, zIndex: 100},
    {name: "GODFATHER", lat: 45.080011, lng: 24.36518, zIndex: 100},
    {name: "KEOPS", lat: 45.039694, lng: 23.271639, zIndex: 100},
    {name: "GORJANUL", lat: 45.042348, lng: 23.27105, zIndex: 100},
    {name: "ZEITA VISINA", lat: 45.193684, lng: 23.391083, zIndex: 100},
    {name: "RIVIERE MP RIVERSIDE", lat: 45.748798, lng: 21.22699, zIndex: 100},
    {name: "LA GIVAN", lat: 45.748839, lng: 21.231645, zIndex: 100},
    {name: "BRASSERIE DUMBRAVITA ", lat: 45.791297, lng: 21.23951, zIndex: 100},
    {name: "FADO IRISH", lat: 44.316654, lng: 23.799732, zIndex: 100},
    {name: "2-4 URBAN", lat: 44.317515, lng: 23.79547, zIndex: 100},
    {name: "COMALEX BAR", lat: 45.293158, lng: 21.938541, zIndex: 100},
    {name: "SARAH NICOLE HOLDING", lat: 45.289395, lng: 21.88790, zIndex: 100},
    {name: "HALYBALY ACTIV", lat: 45.520194, lng: 22.350788, zIndex: 100},
    {name: "DOLCE CASA", lat: 44.349538, lng: 24.10618, zIndex: 100},
    {name: "DIVAMAR PRO HOLDING", lat: 44.427951, lng: 24.366839, zIndex: 100},
    {name: "LA PROMENADA", lat: 44.429166, lng: 24.36375, zIndex: 100},
    {name: "BASTA PIZZA & GRILL", lat: 44.428881, lng: 24.364004, zIndex: 100},
    {name: "CLUB FLEX 1", lat: 46.17483, lng: 21.324, zIndex: 100},
    {name: "BULEVARD CAFE", lat: 46.095402, lng: 21.698884, zIndex: 100},
    {name: "AKADEMIA DE BERE", lat: 45.87894, lng: 22.90294, zIndex: 100},
    {name: "CAFE VERSAILES", lat: 45.869756, lng: 22.912083, zIndex: 100},
    {name: "COZY CORNER", lat: 45.87688, lng: 22.90687, zIndex: 100},
    {name: "BISTRO DIVINE", lat: 45.420066, lng: 23.371384, zIndex: 100},
    {name: "CURTEA BERARILOR - CENTRUL ISTORIC", lat: 44.430592, lng: 26.1007, zIndex: 100},
    {name: "LACRIMI SI SFINTI", lat: 44.430587, lng: 26.102884, zIndex: 100},
    {name: "CITY GRILL - COVACI 2", lat: 44.430412, lng: 26.10263, zIndex: 100},
    {name: "CITY GRILL-PRIMAVERII 2", lat: 44.467069, lng: 26.087847, zIndex: 100},
    {name: "BERARIA GAMBRINUS 2", lat: 44.435049, lng: 26.09485, zIndex: 100},
    {name: "RUBY COFFEE NUTS", lat: 44.44086, lng: 26.095284, zIndex: 100},
    {name: "VICTORIA HUB", lat: 44.44967, lng: 26.0884, zIndex: 100},
    {name: "KATZ GASTROLAB", lat: 44.439786, lng: 26.091462, zIndex: 100},
    {name: "BERARIA GAMBRINUS", lat: 44.43482, lng: 26.09482, zIndex: 100},
    {name: "LIMONCELLO CLUB", lat: 44.431704, lng: 26.102271, zIndex: 100},
    {name: "CEAUN", lat: 44.460441, lng: 26.11970, zIndex: 100},
    {name: "VERDETEI 5", lat: 44.4275, lng: 26.123683, zIndex: 100},
    {name: "TEATRUL INFINIT", lat: 44.446928, lng: 26.11251, zIndex: 100},
    {name: "INDIGEN", lat: 44.464833, lng: 26.119083, zIndex: 100},
    {name: "LA POD ", lat: 44.414293, lng: 26.09802, zIndex: 100},
    {name: "CAFENEAUA ACTORILOR", lat: 44.408099, lng: 26.110405, zIndex: 100},
    {name: "TENIS BISTRO", lat: 44.408755, lng: 26.09779, zIndex: 100},
    {name: "PARDON CAFE", lat: 44.425352, lng: 26.046442, zIndex: 100},
    {name: "PE MOTOARE", lat: 44.46003, lng: 26.04655, zIndex: 100},
    {name: "QUANTIC PUB", lat: 44.437893, lng: 26.059471, zIndex: 100},
    {name: "FLAMINGO", lat: 44.417212, lng: 26.02910, zIndex: 100},
    {name: "CIOTOIANU DRUMUL TABEREI", lat: 44.418785, lng: 26.030697, zIndex: 100},
    {name: "GOLAZZO", lat: 44.427542, lng: 26.0480, zIndex: 100},
    {name: "PLAYA DEL SOL", lat: 44.382165, lng: 25.889586, zIndex: 100},
    {name: "TRATTORIA RICCIO", lat: 44.424834, lng: 26.04505, zIndex: 100},
    {name: "LA STRADA", lat: 44.425705, lng: 26.145034, zIndex: 100},
    {name: "TERASA PERENNA", lat: 44.426, lng: 26.155, zIndex: 100},
    {name: "CHAMPIONS BAR ", lat: 44.412747, lng: 26.14156, zIndex: 100},
    {name: "ANGEL GRILL", lat: 44.409895, lng: 26.12475, zIndex: 100},
    {name: "SQUASH 4ALL", lat: 44.405793, lng: 26.142669, zIndex: 100},
    {name: "MERKANA GRILL & KEBAB", lat: 44.424707, lng: 26.11858, zIndex: 100},
    {name: "BRASSERIE ARENA", lat: 44.432931, lng: 26.152058, zIndex: 100},
    {name: "UNITY BISTRO", lat: 44.605606, lng: 26.12975, zIndex: 100},
    {name: "SO! COFFEE/ COLOSSEUM", lat: 44.491535, lng: 26.01641, zIndex: 100},
    {name: "SO! COFFEE/ BALOTESTI DN1 VALUE CENTER", lat: 44.604143, lng: 26.06765, zIndex: 100},
    {name: "PERU BISTRO", lat: 44.468463, lng: 26.071461, zIndex: 100},
    {name: "AGORA FAMILY ", lat: 44.523482, lng: 26.0061, zIndex: 100},
    {name: "RESTAURANT TERRA ", lat: 44.513361, lng: 25.992686, zIndex: 100},
    {name: "SO! COFFEE/ GARA DE NORD", lat: 44.446481, lng: 26.07378, zIndex: 100},
    {name: "BURGER SI OASE", lat: 44.450464, lng: 26.078627, zIndex: 100},
    {name: "THE LOUNGE BY AQUARIO", lat: 45.159753, lng: 26.82958, zIndex: 100},
    {name: "NORMA RESTAURANT ", lat: 45.151091, lng: 26.813525, zIndex: 100},
    {name: "CALYPSO CRANG ", lat: 45.14986, lng: 26.8036, zIndex: 100},
    {name: "ANTO BEACH", lat: 43.999584, lng: 28.662533, zIndex: 100},
    {name: "TERASA CU FLORI", lat: 44.064639, lng: 28.63433, zIndex: 100},
    {name: "DOI COCOSI", lat: 45.41283, lng: 28.008673, zIndex: 100},
    {name: "BELLA ITALIA", lat: 45.376711, lng: 27.04130, zIndex: 100},
    {name: "BARRELS PUB", lat: 44.259293, lng: 28.618791, zIndex: 100},
    {name: "MAHO BEACH", lat: 44.305441, lng: 28.62832, zIndex: 100},
    {name: "NAVA", lat: 44.307055, lng: 28.628654, zIndex: 100},
    {name: "PUB TOMIS", lat: 44.173755, lng: 28.65753, zIndex: 100},
    {name: "PAPAYA BEACH", lat: 44.199158, lng: 28.659086, zIndex: 100},
    {name: "GELATO ICE&COOFFEE", lat: 45.262046, lng: 27.96985, zIndex: 100},
    {name: "NOIR coffee", lat: 44.561773, lng: 27.358714, zIndex: 100},
    {name: "AVALANSA DEEP 2", lat: 45.1819, lng: 28.80674, zIndex: 100},
    {name: "FRESH GRILL PROJECT SRL ", lat: 45.16134, lng: 28.788266, zIndex: 100},
    {name: "Relax Village", lat: 45.162212, lng: 28.79701, zIndex: 100},
    {name: "MALNAS KAPTAN", lat: 45.427815, lng: 28.053728, zIndex: 100},
    {name: "PARKSIDE NEW", lat: 45.450538, lng: 28.05203, zIndex: 100},
    {name: "BOGDAN MIHALCEA", lat: 44.434269, lng: 26.869168, zIndex: 100},
    {name: "AMOREL MAREA NEAGRA", lat: 45.156236, lng: 29.65357, zIndex: 100},
    {name: "DELTA HOUSE NATURE", lat: 45.229175, lng: 29.257753, zIndex: 100},
    {name: "OPEN BAR", lat: 45.180162, lng: 28.7836, zIndex: 100},
    {name: "SALCII", lat: 44.189226, lng: 27.323921, zIndex: 100},
    {name: "CASA HELIADE", lat: 44.190882, lng: 27.33511, zIndex: 100},
    {name: "Street 102 Gourmet", lat: 45.689674, lng: 27.194499, zIndex: 100},
    {name: "ELIO GABRILION", lat: 45.689115, lng: 27.18551, zIndex: 100},
    {name: "EBOCRILA", lat: 45.261828, lng: 27.969893, zIndex: 100},
    {name: "PANZE", lat: 45.271579, lng: 27.96816, zIndex: 100},
    {name: "ARISTOCRAT PUB", lat: 46.251045, lng: 26.773391, zIndex: 100},
    {name: "BOULEVARD ", lat: 46.556546, lng: 26.91178, zIndex: 100},
    {name: "SNACK BAR", lat: 45.859615, lng: 25.790994, zIndex: 100},
    {name: "KOLCZA", lat: 45.865651, lng: 25.77988, zIndex: 100},
    {name: "NO.1 CAFEE", lat: 45.866413, lng: 25.788176, zIndex: 100},
    {name: "REXO CAFFE", lat: 45.858125, lng: 25.79648, zIndex: 100},
    {name: "CROSSROADS", lat: 45.852533, lng: 25.913263, zIndex: 100},
    {name: "TAVERNA DE NEAMT", lat: 47.204801, lng: 26.36555, zIndex: 100},
    {name: "EVERDUR SRL", lat: 46.923437, lng: 25.347398, zIndex: 100},
    {name: "ROYAL GRUP ", lat: 46.942549, lng: 26.91838, zIndex: 100},
    {name: "LA POPASUL BACIULUI", lat: 47.51362, lng: 25.627238, zIndex: 100},
    {name: "HOTEL MAESTRO", lat: 47.345061, lng: 25.35562, zIndex: 100},
    {name: "SIMPLE BISTRO", lat: 46.290114, lng: 25.034822, zIndex: 100},
    {name: "523 RESTAURANT", lat: 46.305736, lng: 25.29352, zIndex: 100},
    {name: "CSULOK", lat: 46.309863, lng: 25.311393, zIndex: 100},
    {name: "PIZZA LA METRU 2", lat: 47.253441, lng: 26.72370, zIndex: 100},
    {name: "BEST WESTERN BUCOVINA CLUB DE MUNTE ", lat: 47.555151, lng: 25.890867, zIndex: 100},
    {name: "HOTEL ALDI", lat: 47.547202, lng: 25.94116, zIndex: 100},
    {name: "DAION SERCOM SRL (ODEON)", lat: 47.14802, lng: 27.585594, zIndex: 100},
    {name: "GRANPANINO SRL (ROSPETO)", lat: 47.152009, lng: 27.56747, zIndex: 100},
    {name: "RESTAURANT EXPO", lat: 47.187038, lng: 27.560575, zIndex: 100},
    {name: "EUROMEDICAL PROVIDER (CBT)", lat: 47.163056, lng: 27.58213, zIndex: 100},
    {name: "ATELIERUL DE BERE", lat: 47.16029, lng: 27.589635, zIndex: 100},
    {name: "PIDEFOOD S.R.L. (CARAVAN)", lat: 47.163189, lng: 27.58182, zIndex: 100},
    {name: "TERASA MAX", lat: 47.150141, lng: 27.574773, zIndex: 100},
    {name: "INTERSOHO GLOBINVEST SRL (Hanul Sararie)", lat: 47.17638, lng: 27.57971, zIndex: 100},
    {name: "AUGURI", lat: 47.150178, lng: 27.588407, zIndex: 100},
    {name: "DOLCE VITA", lat: 46.63893, lng: 27.73275, zIndex: 100},
    {name: "URBAN/T", lat: 46.657045, lng: 27.737563, zIndex: 100},
    {name: "URBAN T2", lat: 46.66098, lng: 27.73751, zIndex: 100},
    {name: "LEBADA CAFENEA", lat: 46.223996, lng: 27.669428, zIndex: 100},
    {name: "Palariacosmo", lat: 46.23044, lng: 27.66846, zIndex: 100},
    {name: "DOC'S PUB", lat: 46.673862, lng: 28.062739, zIndex: 100},
    {name: "ART CAFE", lat: 46.164175, lng: 24.35585, zIndex: 100},
    {name: "TRATTORIA ROMA", lat: 46.164623, lng: 24.352196, zIndex: 100},
    {name: "THE DOT", lat: 46.162271, lng: 24.34908, zIndex: 100},
    {name: "NORD STAR TRANSPORT", lat: 47.509889, lng: 23.678348, zIndex: 100},
    {name: "BISTRO MOLDOVAN SANNICOARA", lat: 46.791766, lng: 23.72414, zIndex: 100},
    {name: "LUAR CETATE", lat: 46.547727, lng: 24.567753, zIndex: 100},
    {name: "HOTEL DIA LIN PRISLOP", lat: 47.607301, lng: 24.85002, zIndex: 100},
    {name: "BONANZA", lat: 46.798855, lng: 23.470997, zIndex: 100},
    {name: "TRANSYLVANIA STEAKEHOUSE", lat: 46.762104, lng: 23.6114, zIndex: 100},
    {name: "LE CLUB", lat: 46.545102, lng: 24.564717, zIndex: 100},
    {name: "DOME", lat: 46.547035, lng: 24.56404, zIndex: 100},
    {name: "CASA BERII", lat: 46.552214, lng: 24.557047, zIndex: 100},
    {name: "CASA PETRINA", lat: 46.53655, lng: 24.55675, zIndex: 100},
    {name: "RESTAURANT STADION ", lat: 47.662392, lng: 23.579163, zIndex: 100},
    {name: "PODUL VIILOR 9", lat: 47.660885, lng: 23.58028, zIndex: 100},
    {name: "LA TOUR", lat: 47.658605, lng: 23.581035, zIndex: 100},
    {name: "DRU RELAX", lat: 47.662639, lng: 23.49165, zIndex: 100},
    {name: "MARAMURES RESTAURANT", lat: 47.654849, lng: 23.567354, zIndex: 100},
    {name: "HARDWARD PUB", lat: 46.757127, lng: 23.5937, zIndex: 100},
    {name: "WARM UP", lat: 46.767017, lng: 23.57117, zIndex: 100},
    {name: "INSOMNIA EIDOS", lat: 46.768336, lng: 23.58966, zIndex: 100},
    {name: "RHEDEY CAFE", lat: 46.768829, lng: 23.589462, zIndex: 100},
    {name: "TCI SOCIAL CLUB", lat: 46.752264, lng: 23.63207, zIndex: 100},
    {name: "CAFE GRAFFITI &ADI", lat: 46.769579, lng: 23.618478, zIndex: 100},
    {name: "BRUTO", lat: 46.777535, lng: 23.60271, zIndex: 100},
    {name: "ZAZEN", lat: 46.762536, lng: 23.546257, zIndex: 100},
    {name: "PEOPLE", lat: 46.772929, lng: 23.59086, zIndex: 100},
    {name: "LIVING PUB", lat: 46.763804, lng: 23.577119, zIndex: 100},
    {name: "COSMICOM 2 TERASA", lat: 47.473536, lng: 22.56627, zIndex: 100},
    {name: "ROTH ROSE", lat: 47.800409, lng: 22.893038, zIndex: 100},
    {name: "NO PARDON PUB", lat: 47.791414, lng: 22.88384, zIndex: 100},
    {name: "FRIEND'S CAFE", lat: 47.788083, lng: 22.876, zIndex: 100},
    {name: "TOPY BAR", lat: 47.783889, lng: 22.86340, zIndex: 100},
    {name: "BELLA CIAO", lat: 47.789131, lng: 22.870605, zIndex: 100},
    {name: "DAMIAN&MEDEEA PROMARKET", lat: 47.794717, lng: 22.87728, zIndex: 100},
    {name: "NICK'S CAFE", lat: 47.781724, lng: 22.872307, zIndex: 100},
    {name: "SEMCO REGAL", lat: 47.18249, lng: 24.13600, zIndex: 100},
    {name: "NICO PET CORAL SOMESULUI", lat: 47.179399, lng: 24.174577, zIndex: 100},
    {name: "BELLA ROMANTICA BAILE FIGA", lat: 47.163667, lng: 24.20718, zIndex: 100},
    {name: "MOSKVA", lat: 47.058481, lng: 21.930526, zIndex: 100},
    {name: "AMICII", lat: 47.076479, lng: 21.91313, zIndex: 100},
    {name: "LORD'S", lat: 47.054801, lng: 21.929649, zIndex: 100},
    {name: "COOP GHERLA GRADINA DE VARA", lat: 47.031194, lng: 23.90492, zIndex: 100},
    {name: "COUNTRYSIDE HOME", lat: 46.90625, lng: 23.773333, zIndex: 100},
    {name: "CHEERS PUB", lat: 45.787038, lng: 24.14444, zIndex: 100},
    {name: "TIAPANICRIS LA HAN", lat: 47.283693, lng: 24.416063, zIndex: 100},
    {name: "LORDINU CENTRAL", lat: 47.132895, lng: 24.49812, zIndex: 100},
    {name: "CASA SOMESANA", lat: 47.134485, lng: 24.484713, zIndex: 100},
    {name: "SOHO BISTRITZ NOISE", lat: 47.135931, lng: 24.48747, zIndex: 100},
    {name: "AUTHENTIC FLAVOURS", lat: 47.132955, lng: 24.496878, zIndex: 100},
    {name: "BOARDERS VAMA VECHE ", lat: 47.161779, lng: 24.54013, zIndex: 100},
    {name: "AUTOGRIL E60", lat: 46.95469, lng: 22.681558, zIndex: 100},
    {name: "BISTRO LA NINO", lat: 46.864715, lng: 23.02624, zIndex: 100},
    {name: "PIZZA PAZZA", lat: 46.735433, lng: 23.483477, zIndex: 100},
    {name: "HUTING", lat: 46.310052, lng: 23.72332, zIndex: 100},
    {name: "JADOR", lat: 46.070174, lng: 23.560865, zIndex: 100},
    {name: "FRAM'S", lat: 46.070161, lng: 23.57000, zIndex: 100},
    {name: "ART CAFEE DOWNTOWN", lat: 46.069384, lng: 23.578072, zIndex: 100},
    {name: "PUB SKIT 77", lat: 46.069751, lng: 23.54685, zIndex: 100},
    {name: "Ryma Terasa", lat: 46.071923, lng: 23.573483, zIndex: 100},
    {name: "VOYNICH CAFE&PUB", lat: 46.219905, lng: 24.79590, zIndex: 100},
    {name: "ECLIPSE", lat: 46.561076, lng: 24.57444, zIndex: 100},
    {name: "LA CEAUN P-TA SFATULUI", lat: 45.641906, lng: 25.5897, zIndex: 100},
    {name: "Stana Turistica Poiana Bv", lat: 45.609542, lng: 25.536357, zIndex: 100},
    {name: "LA CEAUN - WEISS", lat: 45.643117, lng: 25.59153, zIndex: 100},
    {name: "OGRADA", lat: 45.641807, lng: 25.58944, zIndex: 100},
    {name: "NO COMMENT", lat: 45.144667, lng: 24.67575, zIndex: 100},
    {name: "PAPAO", lat: 44.859527, lng: 24.868189, zIndex: 100},
    {name: "RESTAURANT CURTEA VECHE", lat: 45.143027, lng: 24.6763, zIndex: 100},
    {name: "LA CONAC ", lat: 45.137386, lng: 24.67509, zIndex: 100},
    {name: "E45 RESTOBAR", lat: 44.852785, lng: 24.8767, zIndex: 100},
    {name: "BASTION", lat: 45.358006, lng: 25.547538, zIndex: 100},
    {name: "HOTEL PALACE", lat: 45.354726, lng: 25.55210, zIndex: 100},
    {name: "PASTRAVARIA BRATIOARA", lat: 45.341934, lng: 24.979869, zIndex: 100},
    {name: "TAPPO", lat: 44.928, lng: 25.45955, zIndex: 100},
    {name: "CASA NOASTRA", lat: 44.95605, lng: 25.480893, zIndex: 100},
    {name: "LKS & RAR FOOD", lat: 44.925003, lng: 25.48686, zIndex: 100},
    {name: "BROTACEI", lat: 44.93395, lng: 25.460917, zIndex: 100},
    {name: "CAMPING ZANOAGA", lat: 45.331488, lng: 25.42783, zIndex: 100},
    {name: "CLUB FLACARA", lat: 44.984513, lng: 25.640363, zIndex: 100},
    {name: "CLUBUL VETERANILOR", lat: 44.959419, lng: 25.48293, zIndex: 100},
    {name: "CRAZY PIZZA 2", lat: 44.60392, lng: 25.665763, zIndex: 100},
    {name: "CASA TUDOR", lat: 45.657057, lng: 25.58930, zIndex: 100},
    {name: "TRES OLIVOS", lat: 44.934751, lng: 26.0258, zIndex: 100},
    {name: "EDEN DREAM GARDEN", lat: 44.936253, lng: 26.02944, zIndex: 100},
    {name: "TERASA VERITAS", lat: 44.944928, lng: 26.015952, zIndex: 100},
    {name: "PIZZA FRESCA", lat: 45.031878, lng: 26.02889, zIndex: 100},
    {name: "GUTCRIS BAR", lat: 45.039909, lng: 26.386702, zIndex: 100},
    {name: "OLD INN-TIM 2", lat: 45.844963, lng: 24.97153, zIndex: 100},
    {name: "DA VINCI RESTAURANT", lat: 45.641153, lng: 25.589375, zIndex: 100},
    {name: "PMC AMIGOS", lat: 45.635833, lng: 25.60869, zIndex: 100},
    {name: "URBAN HOME", lat: 44.859655, lng: 24.868062, zIndex: 100},
    {name: "MCV LOGISERV", lat: 45.354472, lng: 26.03877, zIndex: 100},
    {name: "WOKUL MAGIC", lat: 44.939391, lng: 26.022353, zIndex: 100},
    {name: "RAMAYANA", lat: 45.352044, lng: 25.55030, zIndex: 100},
    {name: "STAVILAR - CUTITU DE ARGINT", lat: 45.356444, lng: 25.550833, zIndex: 100},
]