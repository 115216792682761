import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from '../features/auth/authSlice';
import registerReducer from '../features/auth/registerSlice';
import forgetPasswordReducer from '../features/auth/forgetPasswordSlice';
import profileReducer from '../features/profile/profileSlice'
import myGroupReducer from '../features/profile/myGroupSlice'
import personalDataProfileReducer from '../features/profile/personalDataProfileSlice'
import notificationReducer from '../features/notification/notificationSlice'
import ncpReducer from '../features/ncp/ncpSlice';
import externalCampaignReducer from '../features/externalCampaign/externalCampaignSlice';
import gameReducer from '../features/games/gameSlice';
import notificationMiddleware from './middleware/notificationMiddleware';

const store = configureStore({
    reducer: {
        auth: authReducer,
        register: registerReducer,
        forgetPassword: forgetPasswordReducer,
        profile: profileReducer,
        profilePersonalData: personalDataProfileReducer,
        myGroup: myGroupReducer,
        notification: notificationReducer,
        ncp: ncpReducer,
        game: gameReducer,
        externalCampaign: externalCampaignReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(notificationMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;