import React from "react";
import {Grid, Typography} from "@mui/material";
import useBeMemberStyles from "../../styles/kozelMeet/beMember";
import meeet_logo from '../../assets/images/kozel_meet/meeet_logo.png';
import TeaserComponent from "../TeaserComponent";
import {NavLink} from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import ListInfo from "../kozel/ListInfo";
import {useAppSelector} from "../../stores/store";
import {paths} from "../../AppRouters";

const ContentTeaser : React.FC = () => {
    const listItems = [
        {text: 'Prietenii tăi'},
        {text: 'Provocări inedite'},
        {text: 'Premii și experiențe țapene'},
        {text: 'Joculețe interactive, distracție și voie bună'},
        {text: 'Acces la evenimente țapene în locațiile Kozel'},
        {text: 'Te conectezi  cu persoane ce au pasiuni comune, la un mix țapăn Kozel'},
    ]
    return (
        <ListInfo items={listItems} />
    );
}
const BeMember: React.FC = () => {
    const beMemberStyles = useBeMemberStyles();
    const {isAuthenticated} = useAppSelector((state) => state.auth);

    return (
        <Grid container className={beMemberStyles.container}>
            <Grid item xs={12} className={beMemberStyles.box}>
                <img src={meeet_logo} alt={'Kozel meeet logo'} className={beMemberStyles.logo}/>
                <Typography className={beMemberStyles.subLogoText}>Întotdeauna e loc pentru încă un <span className={'color_change'}>prieten!</span></Typography>
                <Grid container className={beMemberStyles.boxBorderDottedContainer}>
                    <Grid item xs={12} className={beMemberStyles.boxBorderDottedItem}>
                        <Typography className={beMemberStyles.textFloatBorder}>Țapăn! <br/> <span>Mă bucur că ești aici.</span></Typography>
                        <Typography className={beMemberStyles.textLittle}>Locul unde prietenii se strâng să împărtășească povești, entuziasm și premii împreună!</Typography>
                        <TeaserComponent
                            title={<Typography className={beMemberStyles.teaserTitle}>CE TE AȘTEAPTĂ AICI:</Typography>}
                            content={<ContentTeaser />}
                        />
                    </Grid>
                    <Grid item xs={12} className={beMemberStyles.sectionItem}>
                        <Typography className={beMemberStyles.textSubDidYouKnow} >Te invit să fii parte din comunitatea Kozel Meeet și să celebrezi fiecare întâlnire cu grupul tău, cu berea ȚAPănă din Cehia!</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {!isAuthenticated && <Grid item xs={12} className={beMemberStyles.sectionItem}>
                <NavLink to={paths.register} className={beMemberStyles.button}>Devin membru</NavLink>
            </Grid>}
            {!isAuthenticated && <Grid item xs={12} className={beMemberStyles.sectionItem}>
                <Typography className={beMemberStyles.textBenefits}><InfoIcon className={beMemberStyles.contentTeaserListItemIcon}/>înscrierea în comunitate îți aduce automat 50 de puncte</Typography>
            </Grid>}
        </Grid>
    );
}

export default BeMember;