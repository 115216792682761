import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography} from '@mui/material';
import {red} from '@mui/material/colors';
import useGlobalStyles from "../styles/global";
import useAgeGateStyles from '../styles/age_gate';
import goat_gif from '../assets/images/goat-this.gif';

interface AgeCheckerProps {
    onAgeSubmit: (age: number, keepIt: boolean) => void;
}

const AgeGate: React.FC<AgeCheckerProps> = ({onAgeSubmit}) => {
    const [year, setYear] = useState<string[]>(['', '', '', '']);
    const [mouth, setMouth] = useState<number | null>(null)
    const [day, setDay] = useState<number | null>(null)
    const inputsRef = useRef<Array<HTMLInputElement | null>>([]);
    const [error, setError] = useState<string>('');
    const [secondStep, setSecondStep] = useState<boolean>(false);
    const [keepIt, setKeepIt] = useState<boolean>(false);

    const globalStyle = useGlobalStyles();
    const ageGateStyle = useAgeGateStyles();

    useEffect(() => {
        resetState();
    }, []);

    const resetState = () => {
        setSecondStep(false);
        setYear(['', '', '', '']);
        setMouth(null);
        setDay(null);
    }
    const resetError = () => {
        setError('');
    }
    const handleChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        resetError();
        const value = event.target.value;
        if (/^\d?$/.test(value)) {
            const newYear = [...year];
            newYear[index] = value;
            setYear(newYear);

            if (value && index < 3) {
                inputsRef.current[index + 1]?.focus();
            }
        }
    };

    const handleBackspace = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !year[index] && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
        resetError();
    };
    const setErrorUnder18 = () => {
        setError('Acest site este interzis persoanelor sub 18 ani.');
    }
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (secondStep) {
            calcByBirthday();
            return;
        }
        calcByYear();
    };

    const calcByBirthday = () => {
        if (mouth === null || day === null || mouth === 0 || mouth > 12 || day === 0) {
            setError('Data nasterii invalida');
            return;
        }
        const birthDate: Date = new Date(parseInt(year.join('')), mouth - 1, day);
        const currentDate: Date = new Date();
        const age = getAge(birthDate, currentDate);
        resetState();
        if ( age < 18) {
            setError('Acest site este interzis persoanelor sub 18 ani.');
            return;
        }

        onAgeSubmit(age, keepIt);
    }
    const calcByYear = () => {
        const enteredYear = parseInt(year.join(''));
        const currentYear = (new Date()).getFullYear();
        const age = currentYear - enteredYear;
        if (age === 18) {
            setSecondStep(true);
            return;
        }
        if (age < 18) {
            setErrorUnder18();
            return;
        }

        onAgeSubmit(age, keepIt);
    }

    const getAge = (dob: Date, when: Date) => {
        let age = 0;

        if (dob.getMonth() > when.getMonth()) {
            age = -1;
        }
        if (dob.getMonth() === when.getMonth() && dob.getDate() > when.getDate()) {
            age = -1;
        }

        return age + when.getFullYear() - dob.getFullYear() ;

    }

    const isYearComplete = year.every(digit => digit.length === 1);

    const handleChangeMouth = (e: ChangeEvent<HTMLInputElement>) => {
        resetError();
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setMouth(parseInt(value));
        }
    }
    const handleChangeDay = (e: ChangeEvent<HTMLInputElement>) => {
        resetError();
        const value = e.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setDay(parseInt(value));
        }
    }

    return (
        <Container maxWidth="md" className={globalStyle.container}>
            <Container className={ageGateStyle.container}>
                <Container className={ageGateStyle.formWrapper}>
                    <img src={goat_gif} alt={'Cool Goat'} className={ageGateStyle.goatGif}/>
                    <Typography className={ageGateStyle.formTitle}>Salut! Trebuie să ai peste 18 ani pentru a ne imprieteni :)</Typography>
                    <Typography className={ageGateStyle.formSubTitle}>Te rugăm să ne menționezi anul nașterii</Typography>
                    {error.length > 0 && <Typography style={{color: red[300]}} variant="h4" gutterBottom>{error}</Typography>}
                    <form onSubmit={handleSubmit}>
                        {secondStep ?
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        className={ageGateStyle.formInput}
                                        label={'Month'}
                                        value={mouth || ''}
                                        type={'number'}
                                        onChange={handleChangeMouth}
                                        variant="outlined"
                                        inputProps={{style: {textAlign: 'center'}}}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className={ageGateStyle.formInput}
                                        label={'Day'}
                                        type={'number'}
                                        value={day || ''}
                                        onChange={handleChangeDay}
                                        variant="outlined"
                                        inputProps={{style: {textAlign: 'center'}}}
                                    />
                                </Grid>
                            </Grid> :
                            <Grid container spacing={2}>
                                {year.map((digit, index) => (
                                    <Grid item xs={3} key={index}>
                                        <TextField
                                            className={ageGateStyle.formInput}
                                            inputRef={(el) => (inputsRef.current[index] = el)}
                                            value={digit}
                                            onChange={handleChange(index)}
                                            onKeyDown={handleBackspace(index)}
                                            variant="outlined"
                                            inputProps={{maxLength: 1, style: {textAlign: 'center'}}}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        }

                        <Button type="submit" variant="contained" color="primary" disabled={!isYearComplete} className={ageGateStyle.formSubmit}>
                            Intra
                        </Button>
                        <FormControlLabel className={ageGateStyle.checkboxKeepIt} control={<Checkbox checked={keepIt} onChange={e => setKeepIt(e.target.checked)} />} label="Ține-mă minte" />
                    </form>
                </Container>
            </Container>
            <Container className={ageGateStyle.footer}>
                <Typography className={ageGateStyle.textCookie} >Folosim cookie-uri pentru îmbunătățirea experienței.</Typography>
                <Typography  className={ageGateStyle.textPolicy}>Accesând site-ul ești de acord cu Politica de Confidențialitate si Termeni și condiții.</Typography>
            </Container>
        </Container>
    );
};

export default AgeGate;
