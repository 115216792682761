import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import TeaserFrame2 from "../TeaserFrame2";
import theme from "../../styles/themes/default";
import {NavLink} from "react-router-dom";
import ListInfo from "../kozel/ListInfo";
import useTeaserGameStyle from "../../styles/kozelMeet/teaserGame";
import bottle_premium_chose from '../../assets/images/kozel_meet/bottle_premium_chose.png';
import bottle_dark_chose from '../../assets/images/kozel_meet/bottle_dark_chose.png';
import tap_in_circle from '../../assets/images/kozel_meet/tap_in_circle.png';
import icon_arrow from '../../assets/images/icon_arrow.png';
import clsx from "clsx";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {setOption} from "../../features/games/gameSlice";
import {paths} from "../../AppRouters";
import {gameNameApi} from "./BottleGame";

const TeaserGame: React.FC = () => {
    const styles = useTeaserGameStyle();
    const dispatch = useAppDispatch();
    const {games} = useAppSelector((state) => state.game);
    const {option} = games[gameNameApi];

    useEffect(() => {
        dispatch(setOption({
            gameName: gameNameApi,
            bottle: 'premium',
        }))
    }, [dispatch]);

    const handleClickBottle = (bottle: string) => {
        dispatch(setOption(
            {
                bottle: bottle
            }
        ))
    }

    return (
        <Grid container className={styles.containerTeaserGame}>
            <Grid item xs={12} className={styles.itemTeaserGame}>
                <TeaserFrame2
                    title={<Typography className={styles.teaserTitle}>Învârte sticla <br/><span
                        className={'underTitle'}>și acceptă provocarea!</span></Typography>}
                    titleMarginTop={'min(-3vh,-3vw)'}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                    colorBorderDotted={theme.colors.desert_sand_gold}
                >
                    <Grid container className={styles.containerContent}>
                        <Grid item xs={12} className={styles.containerItemContent}>
                            <Typography className={styles.subtitleContent}>Ideal, se joacă-n 3 sau mai multi
                                :)</Typography>
                            <Typography className={styles.subtitleContent}>N-ai încă echipa formată? Invită-ti
                                prietenii <NavLink
                                    to={paths.myGroup}>aici.</NavLink> </Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.containerItemContent}>
                            <Grid container className={styles.containerTapZone}>
                                <Grid item lg={4} xs={12} className={styles.boxImgTap}>
                                    <img src={tap_in_circle} alt={'tap_in_circle'} className={styles.imgTap}/>
                                </Grid>
                                <Grid item lg={8} xs={12} className={styles.boxTapMessage}>
                                    <Typography className={styles.tapMessage}>p.s. Dacă vrei să joci oricum, fă-o. ȚAPUL
                                        e
                                        nerăbdător să te provoace!</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={styles.containerItemContent}>
                            <Grid container className={styles.containerSection}>
                                <Grid item lg={4}
                                      className={clsx(styles.containerSectionItem, styles.containerSectionItemColumn)}>
                                    <Typography className={styles.titleContent}>CUM JOC:</Typography>
                                    <img src={icon_arrow} alt={'icon_arrow'} className={styles.arrowImg}/>
                                </Grid>
                                <Grid item lg={8} className={styles.containerSectionItem}>
                                    <ListInfo hasContrast={true} items={[
                                        {
                                            text: 'Îti formezi echipa (dacă nu ai echipă acum, poti juca cu Tapul)',
                                        },
                                        {
                                            text: 'alegi sticla Kozel cu care vrei să învârti prima oară (pont: alege-le pe  ambele, succesiv; ai să vezi că se învârt la fel de ușooooor!)',
                                        },
                                        {
                                            text: 'selectează start joc',
                                        },
                                        {
                                            text: 'provoacă-ti prietenii',
                                        },
                                        {
                                            text: 'fii provocat la rândul tău',
                                        },
                                        {
                                            text: 'asigură-te că provocările sunt respectate :)',
                                        },
                                        {
                                            text: 'câștigaţi puncte și primiţi bere la UNTOLD',
                                        },
                                    ]}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={clsx(styles.containerItemContent, styles.borderDottedTop)}>
                            <Grid container className={styles.containerSection}>
                                <Grid item xs={12} className={styles.containerSectionItem}>
                                    <Typography className={styles.titleContent2}>Alege sticla Kozel</Typography>
                                </Grid>
                                <Grid item xs={12}
                                      className={clsx(styles.containerSectionItem, styles.boxItemBottleChose)}>
                                    <img src={bottle_premium_chose} alt={'bottle_premium_chose'}
                                         className={clsx(styles.imgBottlePremium, (option && option.bottle === 'dark') ? '' : 'selected')}
                                         onClick={() => handleClickBottle('premium')}/>
                                    <img src={bottle_dark_chose} alt={'bottle_dark_chose'}
                                         className={clsx(styles.imgBottleDark, (option && option.bottle === 'dark') ? 'selected' : '')}
                                         onClick={() => handleClickBottle('dark')}/>
                                </Grid>
                                <Grid item xs={12} className={styles.containerSectionItem}>
                                    <NavLink to={paths.gameRollBottle} className={styles.buttonCta}>Start joc</NavLink>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
        </Grid>
    )
}

export default TeaserGame;