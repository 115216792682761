import {Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import totem1 from '../../assets/images/untold/totem1.png';
import totem1_inactiv from '../../assets/images/untold/totem1_inactiv.png';
import totem2 from '../../assets/images/untold/totem2.png';
import totem2_inactiv from '../../assets/images/untold/totem2_inactiv.png';
import totem3 from '../../assets/images/untold/totem3.png';
import totem3_inactiv from '../../assets/images/untold/totem3_inactiv.png';
import totem4 from '../../assets/images/untold/totem4.png';
import totem4_inactiv from '../../assets/images/untold/totem4_inactiv.png';
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {gameNameApi} from "../ChallengeUntold";
import useGameChallengeUntoldHistory from "../../styles/profile/gameChallengeUntoldHistory";
import {getGameStatus} from "../../features/games/gameSlice";

const GameChallengeUntoldHistory: React.FC = () => {
    const styles = useGameChallengeUntoldHistory();
    const dispatch = useAppDispatch();
    const {games} = useAppSelector(state => state.game);
    const {state} = games[gameNameApi];

    useEffect(() => {
        dispatch(getGameStatus({gameName: gameNameApi}))
    }, [dispatch]);
    console.log('state game => ', state);
    if (state === null) {
        return null;
    }

    if (state.lastStep === null) {
        return null
    }

    return (
        <Grid container className={styles.container}>
            <Grid xs={12} className={styles.item}>
                <Typography className={styles.title}>Kozel Meeet Challenges</Typography>
            </Grid>
            <Grid xs={12} className={styles.item}>
                <Typography className={styles.subtitle}>Provocări parcurse</Typography>
            </Grid>
            <Grid item xs={12} className={styles.item}>
                <Grid container className={styles.containerTotem}>
                    <Grid item className={styles.itemTotem}>
                        {state.lastStep >= 1 ?
                            <img src={totem1} alt={'totem1'} className={styles.img}/> :
                            <img src={totem1_inactiv} alt={'totem1_inactiv'} className={styles.img}/>}
                    </Grid>
                    <Grid item className={styles.itemTotem}>
                        {state.lastStep >= 2 ?
                            <img src={totem2} alt={'totem2'} className={styles.img}/> :
                            <img src={totem2_inactiv} alt={'totem2_inactiv'} className={styles.img}/>}
                    </Grid>
                    <Grid item className={styles.itemTotem}>
                        {state.lastStep >= 3 ?
                            <img src={totem3} alt={'totem3'} className={styles.img}/> :
                            <img src={totem3_inactiv} alt={'totem3_inactiv'} className={styles.img}/>}
                    </Grid>
                    <Grid item className={styles.itemTotem}>
                        {state.lastStep > 3 ?
                            <img src={totem4} alt={'totem4'} className={styles.img}/> :
                            <img src={totem4_inactiv} alt={'totem4_inactiv'} className={styles.img}/>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GameChallengeUntoldHistory;