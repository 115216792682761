import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import useHelloComponentStyles from "../../styles/profile/helloComponent";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import goatGif from '../../assets/images/goat-this.gif'
import clsx from "clsx";
import SaveIcon from "@mui/icons-material/Save";
import PersonalDataForm from "../PersonalDataForm";
import ModalWrapper from "../ModalWarpper";
import {updateProfile} from "../../features/profile/profileSlice";

interface StateErrors {
    nickName: '';
}

const HelloComponent: React.FC = () => {
    const styles = useHelloComponentStyles();
    const dispatch = useAppDispatch();
    const { id, nick_name, coins, loading} = useAppSelector(state => state.profile)
    const personalData = useAppSelector((state) => state.profilePersonalData)
    const [nickName, setNickName] = useState(nick_name || '')
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [errors, setErrors] = useState<Partial<StateErrors>>({});

    useEffect(() => {
        setNickName(nick_name || '');
    }, [nick_name]);

    const handleNickNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrors(prevState => ({
            ...prevState,
            nickName: '',
        }));
        setNickName(event.target.value);
    };

    const handleUpdateProfile = () => {
        dispatch(updateProfile(nickName));
    };

    const hasProfileCompleted = () => {
        if (personalData && typeof personalData.agreement_email == 'boolean') {
            return true;
        }
        return false;
    }

    if (loading) {
        return <CircularProgress/>;
    }
    return (
        <Grid container className={styles.wrapperContainer}>
            <Grid item xs={12}>
                <Grid container className={styles.container}>
                    <Grid item xs={12} className={styles.containerItem}>
                        <Typography className={styles.title}>Salut, {nick_name ? nick_name: `G.O.A.T #${id}`}</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.containerItem}>
                        <img className={styles.img} src={goatGif} alt={'goatGif'}/>
                    </Grid>
                    <Grid item xs={12} className={styles.containerItem}>
                        <TextField
                            label="Nick name"
                            fullWidth
                            margin="normal"
                            value={nickName}
                            className={styles.formInput}
                            onChange={handleNickNameChange}
                            error={!!errors.nickName}
                            helperText={errors.nickName}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            color="primary"
                                            onClick={handleUpdateProfile}
                                            disabled={loading}
                                        >
                                            <SaveIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={styles.containerItem}>
                        <Button className={styles.buttonEdit} onClick={() => {
                            setModalIsOpen(true)
                        }}>{hasProfileCompleted() ? 'Editează-ți datele' : 'Completează-ți datele'}</Button>
                    </Grid>
                    <Grid item xs={12} className={clsx(styles.containerItem, styles.borderTopDotted)}>
                        <Typography className={styles.textRank}>Ești <br /><b>JUNIOR VIRTUAL ȚAPSTER</b></Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.containerItem}>
                        <div className={styles.statusCoinsBox}>
                            <Typography className={styles.nrCoins}>{`${coins}`}</Typography>
                            <Typography className={styles.textCoins}>puncte</Typography>
                            <Typography className={styles.textGoats}>Goats</Typography>
                        </div>
                    </Grid>
                </Grid>╝
            </Grid>
            <ModalWrapper isOpen={modalIsOpen} onRequestClose={() => {
                setModalIsOpen(false)
            }}><PersonalDataForm onRequestClose={() => {
                setModalIsOpen(false)
            }}/>
            </ModalWrapper>
        </Grid>
    )
};

export default HelloComponent;