import React, {useState} from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Link,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../stores/store";
import {updatePersonalData} from "../features/auth/authSlice";
import usePersonalDataFormStyles from "../styles/personalDataForm";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";

interface PersonalDataFormProps {
    onRequestClose?: () => void;
}

interface StateErrors {
    accordingMarketingEmail: string,
    birthdate: string,
    email: string,
}

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({onRequestClose}) => {
    const dispatch = useAppDispatch();
    const personalDataFormStyles = usePersonalDataFormStyles();

    const {nick_name, id: profile_id, phone} = useAppSelector((state) => state.profile);
    const personalData = useAppSelector((state) => state.profilePersonalData);
    const [email, setEmail] = useState(personalData.email);
    const [birthdate, setBirthdate] = useState<Dayjs | null>(
        personalData.birthdate ? dayjs(personalData.birthdate) : null
    );
    const [accordMarketingEmail, setAccordMarketingEmail] = useState<boolean | null | undefined>(personalData.agreement_email);

    const [errors, setErrors] = useState<Partial<StateErrors>>({});
    const [isDatePickerBirthdateOpen, setIsDatePickerBirthdateOpen] = useState(false);


    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.values(validationErrors).every(currValue => currValue === '')) {
            const resultAction = await dispatch(updatePersonalData({
                email: accordMarketingEmail ? email : null,
                birthdate: birthdate?.format('YYYY-MM-DD') || '',
                accordMarketingEmail: accordMarketingEmail ?? false
            }));

            if (updatePersonalData.fulfilled.match(resultAction)) {
                if (onRequestClose) {
                    onRequestClose();
                }
            }
        }
    };
    const validateBirthdate = (date: Dayjs | null) => {
        const errMsg = 'Trebuie să aveți cel puțin 18 ani.';
        if (!date) {
            setErrors(prevErrors => ({
                ...prevErrors,
                birthdate: errMsg,
            }));
            return errMsg;
        }
        const today = dayjs();
        const age = today.diff(date, 'year');
        if (age < 18) {
            setErrors(prevErrors => ({
                ...prevErrors,
                birthdate: errMsg,
            }));
            return errMsg;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            birthdate: '',
        }));

        return '';

    };
    const validateAccordMarketingEmail = () => {
        if (accordMarketingEmail === undefined || accordMarketingEmail === null) {
            const errMsg = 'Trebuie sa alegeți o opțiune pentru a salva formularul.';
            setErrors(prevErrors => ({
                ...prevErrors,
                accordingMarketingEmail: errMsg,
                email: '',
            }));
            return errMsg;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            accordingMarketingEmail: '',
            email: '',
        }));

        return '';
    }
    const validateFields = () => {
        const newErrors: Partial<StateErrors> = {};
        newErrors.accordingMarketingEmail = validateAccordMarketingEmail();
        if (accordMarketingEmail) {
            newErrors.email = validateEmail(email);
        }
        newErrors.birthdate = validateBirthdate(birthdate);

        setErrors(newErrors);

        return newErrors;
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            const errMsg = 'Email invalid';
            setErrors(prevErrors => ({
                ...prevErrors,
                email: errMsg,
            }));

            return errMsg;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            email: '',
        }));

        return '';
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    };

    const handleBirthdateChange = (newValue: Dayjs | null) => {
        setBirthdate(newValue);
        validateBirthdate(newValue);
        setIsDatePickerBirthdateOpen(false);
    };

    const handleBirthdateFocus = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDatePickerBirthdateOpen(true);
    };


    const handleRequestCloseModal = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (onRequestClose) {
            onRequestClose();
        }
    }

    return (
        <Grid container className={personalDataFormStyles.container}>
            <Grid item xs={12} className={personalDataFormStyles.formContainer}>
                <Typography className={personalDataFormStyles.title}>
                    Salut, {nick_name ? nick_name : 'G.O.A.T.#' + profile_id}
                </Typography>
                <Typography className={personalDataFormStyles.subtitle}>
                    -Completează-ți profilul și câștigă 50 de puncte!-
                </Typography>
                <Link className={personalDataFormStyles.descriptionText} href={'https://www.facebook.com/kozelro'}
                      target="_blank" rel="noopener">Vezi aici ce poți face cu punctele strânse</Link>
                {personalData.error &&
                    <Typography className={personalDataFormStyles.errorText}>{personalData.error}</Typography>}
                <DatePicker
                    value={birthdate}
                    onAccept={handleBirthdateChange}
                    label={'Data nașterii*'}
                    open={isDatePickerBirthdateOpen}
                    onOpen={() => setIsDatePickerBirthdateOpen(true)}
                    onClose={() => setIsDatePickerBirthdateOpen(false)}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!errors.birthdate,
                            helperText: errors.birthdate,
                            className: personalDataFormStyles.formInput,
                            variant: 'outlined',
                            color: 'primary',
                            onClick: handleBirthdateFocus,
                        },
                    }}
                />

                <TextField
                    label="Phone Number"
                    fullWidth
                    margin="normal"
                    value={phone}
                    className={personalDataFormStyles.formInput}
                    disabled
                    aria-readonly={true}
                />

                <FormControl className={personalDataFormStyles.formControlCheckbox}>
                    <FormLabel className={personalDataFormStyles.formControlCheckboxLabel}>Ești deacord să fii contactat
                        prin email pentru a
                        primi comunicări de marketing (noutăți, lansări, promoții)?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={accordMarketingEmail}
                        onChange={(e) => {
                            const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;
                            setAccordMarketingEmail(value);
                            validateAccordMarketingEmail();
                        }}
                    >
                        <FormControlLabel value={'true'} control={<Radio/>} label="Da"
                                          onClick={() => {
                                              setAccordMarketingEmail(true);
                                              validateAccordMarketingEmail();
                                          }}/>
                        <FormControlLabel value={'false'} control={<Radio/>} label="Nu"
                                          onClick={() => {
                                              setAccordMarketingEmail(false);
                                              validateAccordMarketingEmail();
                                          }}/>
                    </RadioGroup>
                    {errors.accordingMarketingEmail && (
                        <FormHelperText>{errors.accordingMarketingEmail}</FormHelperText>
                    )}
                </FormControl>
                <TextField
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    fullWidth
                    style={{visibility: accordMarketingEmail ? 'visible' : 'hidden'}}
                    className={personalDataFormStyles.formInput}
                />
                <Grid container>
                    <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                        <Button onClick={handleRequestCloseModal}
                                className={personalDataFormStyles.cancelButton}>Cancel</Button>
                        <Button onClick={handleSave} className={personalDataFormStyles.submitButton}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PersonalDataForm;
